<template>
  <q-page class="q-pa-none">
    <div class="container">
      <section
        class="q-my-auto flex flex-center q-pb-lg relative-position"
        style="min-height: calc(100vh - 97px)"
      >
        <div class="q-mx-md text-center full-width">
          <div class="row justify-center" style="max-height: 100% !important">
            <div class="col-12 col-sm-6 flex justify-center">
              <q-card
                class="full-width bg-transparent"
                flat
                style="max-width: 600px"
              >
                <q-card-section class="">
                  <h2 class="text-h4">
                    {{ t("contents.sections.0.title") }}
                    <q-spinner-dots color="primary" size="1em" />
                  </h2>
                </q-card-section>
                <q-card-section>
                  <pair-programming-svg />
                </q-card-section>
              </q-card>
            </div>
          </div>
        </div>
        <div class="absolute-bottom flex flex-center">
          <q-icon
            name="south"
            color="grey"
            size="5rem"
            class="cursor-pointer"
            style="
              animation: pulse 2s infinite;
              animation-delay: 1s;
              animation-duration: 2s;
              opacity: 0.5;
            "
            @click="scrollTo('#section-1')"
          />
          <!-- <q-btn
              class="animated pulse infinite"
              style="animation-duration: 2s; animation-delay: 1s"
              round
              flat
              color="accent"
              icon="keyboard_arrow_down"
              padding="none"
              size="xl"
            /> -->
        </div>
      </section>
      <section
        id="section-1"
        class="q-my-auto flex flex-center"
        style="min-height: calc(100vh - 97px)"
      >
        <div class="q-mx-md text-left full-width">
          <div class="row justify-center justify-sm-end">
            <div class="col-12 col-sm-6 flex justify-end">
              <q-intersection transition="scale" once ssr-prerender>
                <q-card class="full-width" flat style="max-width: 600px">
                  <q-card-section horizontal>
                    <q-separator
                      spaced
                      vertical
                      color="primary"
                      size="3px"
                      inset
                    />
                    <q-card-section class="col">
                      <h2 class="q-mt-none text-h4">
                        {{ t("contents.sections.1.title") }}
                      </h2>
                      <p class="q-mb-none">
                        {{ t("contents.sections.1.description") }}
                      </p>
                    </q-card-section>
                  </q-card-section>
                  <q-card-section>
                    <car-rental-svg />
                  </q-card-section>
                </q-card>
              </q-intersection>
            </div>
          </div>
        </div>
      </section>
      <section
        class="q-my-auto flex flex-center"
        style="min-height: calc(100vh - 97px)"
      >
        <div class="q-mx-md text-left full-width">
          <div class="row">
            <div class="col-12 col-sm-6">
              <q-intersection transition="scale" once ssr-prerender>
                <q-card class="full-width" flat style="max-width: 600px">
                  <q-card-section horizontal>
                    <q-separator
                      spaced
                      vertical
                      color="primary"
                      size="3px"
                      inset
                    />
                    <q-card-section class="col-grow">
                      <h2 class="q-mt-none text-h4">
                        {{ t("contents.sections.2.title") }}
                      </h2>
                      <q-list dense>
                        <q-item
                          dense
                          tag="li"
                          v-for="item in 5"
                          :key="item"
                          class="no-padding flex items-center"
                        >
                          <q-icon name="circle" color="dark" size="10px" left />
                          {{ t(`contents.sections.2.description.${item}`) }}
                        </q-item>
                      </q-list>
                    </q-card-section>
                  </q-card-section>
                  <q-card-section>
                    <order-ride-svg />
                  </q-card-section>
                </q-card>
              </q-intersection>
            </div>
          </div>
        </div>
      </section>
      <section
        class="q-my-auto flex flex-center relative-position styled-background-2"
        style="min-height: calc(100vh - 97px)"
      >
        <div class="q-mx-md text-center q-mb-xl full-width">
          <div class="row justify-center">
            <q-intersection transition="scale" once ssr-prerender>
              <q-card
                class="full-width bg-transparent"
                flat
                style="max-width: 600px"
              >
                <q-card-section>
                  <h2 class="text-h4">
                    {{ t("contents.sections.3.title") }}
                  </h2>
                  <q>
                    {{ t("contents.sections.3.description") }}
                  </q>
                  <p class="q-mt-md">
                    <strong>
                      {{ t("contents.sections.3.signature") }}
                    </strong>
                  </p>
                </q-card-section>
              </q-card>
            </q-intersection>
          </div>
        </div>
        <div class="full-width flex flex-center q-py-lg" v-if="false">
          <q-btn label="Contact Us" color="primary" />
        </div>
      </section>
    </div>
  </q-page>
</template>
<i18n>
  {
    "en-US": {
      "metas": {
        "title" : "Buying and selling cars in Switzerland",
        "description": "Free marketplace for buying and selling new and used cars in Switzerland. Quickly find your next car or sell yours.",
      },
      "contents": {
        "sections": [
          {
            "title": "In build",
          },
          {
              "title": "Simply better",
              "description": "The future free and intuitive marketplace for buying and selling new and used cars in Switzerland. Equally well-designed for professionals as it is simple for individuals.",
          },
          {
            "title": "Automarket is:",
            "description": [
              "Completely free use with premium options",
              "Ultra-fast integration of new listings",
              "Fluid and ergonomic vehicle exploration",
              "Optimal simplicity for individuals",
              "Exclusive features for professionals",
              "Translation in four languages: DE, FR, IT, EN"
            ],
          },
          {
            "title": "Business partners",
            "description": "Dear esteemed future business partners, we eagerly anticipate collaborating with your distinguished services, an alliance we believe will significantly enhance the stature of Automarket.ch in the Swiss automotive marketplace",
            "signature": "Juicy Web Sàrl Team",
          }
        ],
      },
    },
    "fr": {
      "metas": {
        "title" : "Achat et vente de voitures en Suisse",
        "description": "Marketplace gratuite pour l'achat et la vente de voitures neuves et d'occasion en Suisse. Trouvez rapidement votre prochaine voiture ou vendez la vôtre.",
      },
      "contents": {
        "sections": [
          {
            "title": "En construction",
          },
          {
            "title": "Simplement mieux",
            "description": "La future marketplace gratuite et intuitive pour l’achat et la vente de voitures neuves et d’occasion en Suisse. Aussi bien pensé pour les professionnels que simple d'utilisation pour les particuliers.",
          },
          {
            "title": "Automarket c'est :",
            "description": [
              "Utilisation entièrement gratuite avec options premium",
              "Intégration ultra-rapide de nouvelles annonces",
              "Exploration de véhicules fluide et ergonomique",
              "Simplicité optimale pour les particuliers",
              "Fonctionnalités exclusives pour professionnels",
              "Traduction en quatre langues : DE, FR, IT, EN"
            ],
          },
          {
            "title": "Partenaires commerciaux",
            "description": "Chers futurs partenaires commerciaux, nous sommes impatients de collaborer avec vos services distingués, une alliance qui, nous le croyons, rehaussera considérablement le statut d'Automarket.ch sur le marché automobile suisse",
            "signature": "L'équipe de Juicy Web Sàrl",
          }
        ],
      },
    },
    "de": {
      "metas": {
        "title" : "Kauf und Verkauf von Autos in der Schweiz",
        "description": "Marketplace kostenlos für den Kauf und Verkauf von neuen und gebrauchten Autos in der Schweiz. Finden Sie schnell Ihr nächstes Auto oder verkaufen Sie Ihr eigenes.",
      },
      "contents": {
        "sections": [
          {
            "title": "In Bau",
          },
          {
            "title": "Einfach besser",
            "description": "Der zukünftige kostenlose und intuitive Marktplatz für den Kauf und Verkauf von neuen und gebrauchten Fahrzeugen in der Schweiz. Ebenso gut für Profis konzipiert wie einfach für Einzelpersonen.",
          },
          {
            "title": "Automarket ist:",
            "description": [
              "Vollständig kostenlose Nutzung mit Premium-Optionen",
              "Ultra-schnelle Integration neuer Anzeigen",
              "Flüssige und ergonomische Fahrzeugerkundung",
              "Optimale Einfachheit für Privatpersonen",
              "Exklusive Funktionen für Fachleute",
              "Übersetzung in vier Sprachen: DE, FR, IT, EN"
            ],
          },
          {
            "title": "Geschäftspartner",
            "description": "Liebe geschätzte zukünftige Geschäftspartner, wir freuen uns auf die Zusammenarbeit mit Ihren ausgezeichneten Dienstleistungen, eine Allianz, von der wir glauben, dass sie das Ansehen von Automarket.ch auf dem Schweizer Automobilmarkt erheblich verbessern wird",
            "signature": "Juicy Web Sàrl Team",
          }
        ],
      },
    },
    "it": {
      "metas": {
        "title" : "Acquisto e vendita di auto in Svizzera",
        "description": "Marketplace gratuito per l'acquisto e la vendita di auto nuove e usate in Svizzera. Trova rapidamente la tua prossima auto o vendi la tua.",
      },
      "contents": {
        "sections": [
          {
            "title": "In costruzione",
          },
          {
            "title": "Semplicemente meglio",
            "description": "Marketplace gratuito per l'acquisto e la vendita di auto nuove e usate in Svizzera. Trova rapidamente la tua prossima auto o vendi la tua.",
          },
          {
            "title": "Automarket è:",
            "description": [
              "Utilizzo completamente gratuito con opzioni premium",
              "Integrazione ultra-rapida di nuovi annunci",
              "Esplorazione di veicoli fluida ed ergonomica",
              "Semplicità ottimale per i privati",
              "Funzionalità esclusive per professionisti",
              "Traduzione in quattro lingue: DE, FR, IT, EN"
            ],
          },
          {
            "title": "Partner commerciali",
            "description": "Stimati futuri partner commerciali, non vediamo l'ora di collaborare con i vostri servizi distinti, un'alleanza che riteniamo migliorerà significativamente lo status di Automarket.ch nel mercato automobilistico svizzero",
            "signature": "Juicy Web Sàrl Team",
          }
        ],
      },
    },
  }
</i18n>
<script>
import { defineComponent /** , onErrorCaptured */ } from "vue";
import { useI18n } from "vue-i18n";
import { useMeta, scroll } from "quasar";
import PairProgrammingSvg from "src/components/svg/PairProgrammingSvg.vue";
import CarRentalSvg from "src/components/svg/CarRentalSvg.vue";
import OrderRideSvg from "src/components/svg/OrderRideSvg.vue";
const { getScrollTarget, setVerticalScrollPosition } = scroll;

export default defineComponent({
  name: "landing-page",
  components: {
    PairProgrammingSvg,
    CarRentalSvg,
    OrderRideSvg,
  },
  setup() {
    // Debbug purpose
    // onErrorCaptured((error, instance, info) => {
    //   console.error("Erreur capturée dans setup():", error);
    //   // Gestion de l'erreur
    //   return false; // Si vous retournez false, l'erreur ne se propagera pas plus loin
    // });

    const { t } = useI18n();

    const scrollTo = (selector) => {
      if (process.env.SERVER) return;
      const el = document.querySelector(selector);
      if (!el) return;
      const target = getScrollTarget(el);
      const offset = el.offsetTop;
      const duration = 500;
      setVerticalScrollPosition(target, offset, duration);
    };

    useMeta(() => {
      return {
        title: t("metas.title"),
        titleTemplate: (title) => `AutoMarket - ${title}`,
        meta: {
          description: {
            name: "description",
            content: t("metas.description"),
          },
          ogTitle: {
            property: "og:title",
            template(ogTitle) {
              return `AutoMarket - ${ogTitle}`;
            },
          },
          ogDescription: {
            property: "og:description",
            content: t("metas.description"),
          },
          // TODO:
          ogImage: {
            property: "og:image",
            content: "https://automarket.ch/icon-512x512.png",
          },
          twitterTitle: {
            name: "twitter:title",
            template(twitterTitle) {
              return `AutoMarket - ${twitterTitle}`;
            },
          },
          twitterDescription: {
            name: "twitter:description",
            content: t("metas.description"),
          },
          // TODO:
          twitterImage: {
            name: "twitter:image",
            content: "https://automarket.ch/icon-512x512.png",
          },
          // Optinals meta tags (or with default value in App.vue):
          // keywords: { name: 'keywords', content: t('metaData.keywords') },
          // robots: { name: 'robots', content: 'noindex, nofollow' },
          // author: { name: "author", content: "AutoMarket.ch" },
          // ogType: { property: 'og:type', content: 'article' },
          // ogAudio: { property: 'og:audio', content: 'URL de l\'audio' },
          // ogVideo: { property: 'og:video', content: 'URL de la vidéo' },
          // twitterCard: { name: 'twitter:card', content: 'type de carte (ex. summary, summary_large_image)' },
          // twitterCreator: { name: 'twitter:creator', content: '@nomUtilisateurTwitterDeLAuteur' },
        },
        // link: {
        //   canonical: { rel: 'canonical', href: 'URL canonique de la page' },
        //   prev: { rel: 'prev', href: 'URL de la page précédente' },
        //   next: { rel: 'next', href: 'URL de la page suivante' },
        //   preconnect: { rel: 'preconnect', href: 'https://domaine.com' },
        //   prefetch: { rel: 'prefetch', href: 'https://domaine.com' },
        //   dnsPrefetch: { rel: 'dns-prefetch', href: 'https://domaine.com' },
        // },
        // script: [
        //   {
        //     type: "application/ld+json",
        //     json: {
        //       "@context": "https://schema.org",
        //       "@graph": [
        //         {
        //           "@type": "WebPage",
        //           url: "https://www.votresite.com/chemin-de-la-page",
        //           name: t("metas.title"),
        //           description: t("metas.description"),
        //           inLanguage: "fr-FR",
        //           isPartOf: {
        //             "@type": "WebSite",
        //             name: "AutoMarket",
        //             url: "https://automarket.ch/",
        //           },
        //           breadcrumb: {
        //             "@type": "BreadcrumbList",
        //             itemListElement: [
        //               // Éléments de Breadcrumb...
        //             ],
        //           },
        //           mainEntity: {
        //             // Entité principale de la page...
        //           },
        //           // Plus de propriétés...
        //         },
        //       ],
        //     },
        //   },
        // ],
      };
    });

    return {
      t,
      scrollTo,
    };
  },
});
</script>

<style scoped>
.styled-background-2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("/road.webp") center center / cover no-repeat;
  opacity: 0.2; /* Ajustez pour la transparence souhaitée */
  z-index: -1;
}
</style>
