export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "metas": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying and selling cars in Switzerland"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free marketplace for buying and selling new and used cars in Switzerland. Quickly find your next car or sell yours."])}
        },
        "contents": {
          "sections": [
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In build"])}
            },
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simply better"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The future free and intuitive marketplace for buying and selling new and used cars in Switzerland. Equally well-designed for professionals as it is simple for individuals."])}
            },
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automarket is:"])},
              "description": [
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completely free use with premium options"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultra-fast integration of new listings"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluid and ergonomic vehicle exploration"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal simplicity for individuals"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive features for professionals"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation in four languages: DE, FR, IT, EN"])},
                
              ]
            },
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business partners"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear esteemed future business partners, we eagerly anticipate collaborating with your distinguished services, an alliance we believe will significantly enhance the stature of Automarket.ch in the Swiss automotive marketplace"])},
              "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juicy Web Sàrl Team"])}
            }
          ]
        }
      },
      "fr": {
        "metas": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat et vente de voitures en Suisse"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace gratuite pour l'achat et la vente de voitures neuves et d'occasion en Suisse. Trouvez rapidement votre prochaine voiture ou vendez la vôtre."])}
        },
        "contents": {
          "sections": [
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En construction"])}
            },
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplement mieux"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La future marketplace gratuite et intuitive pour l’achat et la vente de voitures neuves et d’occasion en Suisse. Aussi bien pensé pour les professionnels que simple d'utilisation pour les particuliers."])}
            },
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automarket c'est :"])},
              "description": [
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisation entièrement gratuite avec options premium"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration ultra-rapide de nouvelles annonces"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exploration de véhicules fluide et ergonomique"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplicité optimale pour les particuliers"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnalités exclusives pour professionnels"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction en quatre langues : DE, FR, IT, EN"])},
                
              ]
            },
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenaires commerciaux"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chers futurs partenaires commerciaux, nous sommes impatients de collaborer avec vos services distingués, une alliance qui, nous le croyons, rehaussera considérablement le statut d'Automarket.ch sur le marché automobile suisse"])},
              "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'équipe de Juicy Web Sàrl"])}
            }
          ]
        }
      },
      "de": {
        "metas": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf und Verkauf von Autos in der Schweiz"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace kostenlos für den Kauf und Verkauf von neuen und gebrauchten Autos in der Schweiz. Finden Sie schnell Ihr nächstes Auto oder verkaufen Sie Ihr eigenes."])}
        },
        "contents": {
          "sections": [
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bau"])}
            },
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfach besser"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der zukünftige kostenlose und intuitive Marktplatz für den Kauf und Verkauf von neuen und gebrauchten Fahrzeugen in der Schweiz. Ebenso gut für Profis konzipiert wie einfach für Einzelpersonen."])}
            },
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automarket ist:"])},
              "description": [
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständig kostenlose Nutzung mit Premium-Optionen"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultra-schnelle Integration neuer Anzeigen"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flüssige und ergonomische Fahrzeugerkundung"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimale Einfachheit für Privatpersonen"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exklusive Funktionen für Fachleute"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzung in vier Sprachen: DE, FR, IT, EN"])},
                
              ]
            },
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftspartner"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liebe geschätzte zukünftige Geschäftspartner, wir freuen uns auf die Zusammenarbeit mit Ihren ausgezeichneten Dienstleistungen, eine Allianz, von der wir glauben, dass sie das Ansehen von Automarket.ch auf dem Schweizer Automobilmarkt erheblich verbessern wird"])},
              "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juicy Web Sàrl Team"])}
            }
          ]
        }
      },
      "it": {
        "metas": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisto e vendita di auto in Svizzera"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace gratuito per l'acquisto e la vendita di auto nuove e usate in Svizzera. Trova rapidamente la tua prossima auto o vendi la tua."])}
        },
        "contents": {
          "sections": [
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In costruzione"])}
            },
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semplicemente meglio"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace gratuito per l'acquisto e la vendita di auto nuove e usate in Svizzera. Trova rapidamente la tua prossima auto o vendi la tua."])}
            },
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automarket è:"])},
              "description": [
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzo completamente gratuito con opzioni premium"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrazione ultra-rapida di nuovi annunci"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esplorazione di veicoli fluida ed ergonomica"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semplicità ottimale per i privati"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funzionalità esclusive per professionisti"])},
                (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduzione in quattro lingue: DE, FR, IT, EN"])},
                
              ]
            },
            {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner commerciali"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimati futuri partner commerciali, non vediamo l'ora di collaborare con i vostri servizi distinti, un'alleanza che riteniamo migliorerà significativamente lo status di Automarket.ch nel mercato automobilistico svizzero"])},
              "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juicy Web Sàrl Team"])}
            }
          ]
        }
      }
    }
  })
}
